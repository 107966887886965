import { getList } from "./api.js";
import navList from "./navList";
import Filtrate from "./components/Filtrate";

export default {
  components: {
    Filtrate
  },
  data() {
    return {
      pageLoadFlag: false,
      apllyMode: false, //申请模式
      navList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/finaceClaim",
          name: "finaceClaim",
          title: "资金认领"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      scrollApply: {
        x: 2000
        // y: 400
      },
      columnsApply: [
        {
          label: "收款认领通知单",
          prop: "billno",
          ellipsis: true,
          width: "160px",
          key: "billno"
        },
        {
          label: "资金组织",
          prop: "org",
          ellipsis: true,
          width: "260px",
          key: "org"
        },
        {
          label: "海信收款金额",
          prop: "reamount",
          ellipsis: true,
          align:'right',
          width: "110px",
          key: "reamount"
        },
        {
          label: "未认领金额",
          prop: "unclaimamount",
          ellipsis: true,
          width: "110px",
          align:'right',
          key: "unclaimamount"
        },
        {
          label: "认领金额",
          prop: "claimedamount",
          ellipsis: true,
          width: "80px",
          align:'right',
          key: "claimedamount"
        },
        {
          label: "流水类型",
          prop: "hifi_flowstatusName",
          ellipsis: true,
          width: "80px",
          key: "hifi_flowstatusName"
        },
        {
          label: "对方户名",
          prop: "oppunit",
          ellipsis: true,
          width: "200px",
          key: "oppunit"
        },
        {
          label: "认领状态",
          prop: "claimstatus",
          ellipsis: true,
          width: "80px",
          key: "claimstatus"
        },
        {
          label: "合并状态",
          prop: "ismerge",
          key: "ismerge",
          ellipsis: true,
          align:'right',
          width: "80px"
        },
        {
          label: "交易/登记时间",
          prop: "tradetime",
          ellipsis: true,
          width: "140px",
          key: "tradetime"
        }
      ],
      data: [],
      onlyShowChecked: false,
      selRows: [], //选总行scroll
      selectedRowKeys: [],
      params: {}
    };
  },
  computed: {
    //表格数据/切换只显示勾选使用
    filterData() {
      if (this.onlyShowChecked) {
        const billnos = this.selRows.map(it => it.billno);
        const resu = this.data.filter(it => {
          return billnos.includes(it.billno);
        });
        return resu;
      } else {
        return this.data;
      }
    },
    total() {
      return this.selRows.reduce((sum, item) => {
        return sum + (item.unclaimamount || 0);
      }, 0);
    }
  },
  created() {
    this.getData();
    this.navList = navList.nav1;
  },
  methods: {
    checkSelectable(row) {
      return row.unclaimamount > 0
    },
    getRowKey (row) {
      return row.billno
    },
    clearSelection() {
      this.selectedRowKeys = [];
      this.selRows = [];
      if(this.$refs.tabins&&this.$refs.tabins.$refs.tabInst){
        this.$refs.tabins.$refs.tabInst.clearSelection();
      }
    },
    //行选中，取消
    onChange(selRows) {
      this.selRows = selRows;
      this.selectedRowKeys = selRows.map(it=>it.billno);
    },
    gotoApply() {
      if (this.selRows.length != 1) {
        this.$message.warning("请选择一条数据进行认领！");
        return;
      }
      if (this.selRows[0].unclaimamount <= 0) {
        this.$message.warning("请选择未认领金额大于0的记录！");
        return;
      }
      this.$router.push({
        path: "/finaceClaim/claim",
        query: {
          id: this.selRows[0].id,
          orgCode: this.selRows[0].saleorg.number
        }
      });
    },
    gotoDetail() {
      if (this.selRows.length != 1) {
        this.$message.warning("请选择一条数据进行查看！");
        return;
      }
      this.$router.push({
        path: "/finaceClaim/detail",
        query: {
          id: this.selRows[0].id,
          orgCode: this.selRows[0].saleorg.number
        }
      });
    },
    searchFun(arg) {
      this.params = arg[0];
      this.pager.pageNo = 1
      this.getData();
    },
    //排序change事件
    onTableChange(pagination, filters, sorter, currentDataSource) {
      this.getData();
    },
    getData() {
      const data = {
        method: "queryNoticeBills",
        params: {
          pageno: this.pager.pageNo,
          pagesize: this.pager.pageSize,
         ...this.params
        }
      };
      this.clearSelection();
      getList(data,this.$route.query.id).then(res => {
        res.data.data.datas.forEach(item => {
          item.hifi_flowstatusName =
            item.hifi_flowstatus == "account"
              ? "银行流水"
              : item.hifi_flowstatus == "draftbill"
              ? "票据流水"
              : item.hifi_flowstatus;
        });
        this.data = res.data.data.datas;
        this.pager.count = res.data.data.count;
        this.selRows = [];
      });
    }
  }
};
