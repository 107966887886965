<!--
 * @Author: sqk
 * @Date: 2020-09-02 10:28:52
 * @LastEditTime: 2021-01-25 19:00:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\filtrate\Filtrate.vue
-->

<template>
  <a-spin class="mid-spin" :spinning="false" size="large">
    <div class="formFiltrate-box clearfix" :class="{ more: isShowMoreFlag }">
      <div class="tool-box">
        <div class="btn-group">
          <h-btn :width="66" :height="32" content="搜索" @click="searchFun" />
          <h-btn :width="66" :height="32" :customType="3" content="重置" @click="resetFun" />
        </div>
        <h-btn
            v-if="isToggleFlag"
            class="zk"
            :width="40"
            :height="28"
            :content="isShowMoreFlag ? '收起' : '展开'"
            :level="3"
            @click="isShowMoreFlag = !isShowMoreFlag"
        />
      </div>
      <ul
        class="form clearfix"
        :style="{ 'padding-left': formDistance + 'px' }"
      >
        <li :style="setStyle(0)">
          <span class="lab">交易时间:</span>
          <div class="form-item-box">
            <a-range-picker
              v-model="time"
              @change="
                (date, dateString) => {
                  indateChange(date, dateString, time);
                }
              "
              type="date"
            >
              <a-icon slot="suffixIcon" type="calendar" />
            </a-range-picker>
          </div>
        </li>
        <li :style="setStyle(1)">
          <span class="lab">用户:</span>
          <div class="form-item-box">
            <a-input v-model="filForm.user" allowClear placeholder="请输入" />
          </div>
        </li>
        <li :style="setStyle(2)">
          <span class="lab">流水状态:</span>
          <div class="form-item-box">
            <a-select
              v-model="filForm.hifi_flowstatus"
              :maxTagCount="2"
              showSearch
              showArrow
              allowClear
              placeholder="请选择"
            >
              <a-select-option
                v-for="option in flowList"
                :key="option.id"
                :value="option.id"
                >{{ option.name }}
              </a-select-option>
            </a-select>
          </div>
        </li>
        <li :style="setStyle(3)">
          <span class="lab">通知单状态:</span>
          <div class="form-item-box">
            <a-select
              v-model="filForm.claimstatus"
              :maxTagCount="2"
              showSearch
              showArrow
              allowClear
              placeholder="请选择"
            >
              <a-select-option
                v-for="option in mesList"
                :key="option.id"
                :value="option.id"
                >{{ option.name }}
              </a-select-option>
            </a-select>
          </div>
        </li>
      </ul>
    </div>
  </a-spin>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Filtrate",
  data() {
    return {
      maxSize: [], //每列最大字数
      isToggleFlag: false, //是否显示‘收起/展开按钮’ （是否筛选项超过6条）
      isShowMoreFlag: false, //true->收起（已展开）false->展开（已收起）
      filForm: {
        tradetimeS: "",
        tradetimeE: "",
        user: "",
        claimstatus: "",
        hifi_flowstatus: ""
      },
      time: [],
      flowList: [
        { id: "", name: "全部" },
        { id: "account", name: "银行流水" },
        { id: "pos", name: "POS流水" },
        { id: "draftbill", name: "票据流水" }
      ],
      mesList: [
        { id: "", name: "全部" },
        { id: "0", name: "待认领" },
        { id: "1", name: "部分认领" },
        { id: "2", name: "已认领" },
        { id: "3", name: "已确认" },
        { id: "4", name: "申诉中" },
        { id: "5", name: "变更中" }
      ]
    };
  },
  props: {
    //设置表单之间边距
    itemDistance: {
      type: [Number, String],
      default: 24
    },
    //表单整体左边距
    formDistance: {
      type: [Number, String],
      default: 24
    }
  },
  mounted() {},
  methods: {
    //搜索
    searchFun() {
      this.callBack();
    },
    //重置
    resetFun() {
      // this.$refs['form'].resetFields();
      Object.keys(this.filForm).forEach(key => {
        this.filForm[key] = undefined;
      });
      this.time =[]
      this.filForm.tradetimeS = "";
      this.filForm.tradetimeE = "";
      this.callBack();
    },
    //回调传出
    callBack() {
      this.$emit("search", [
        JSON.parse(JSON.stringify(this.filForm)),
      ]);
    },
    //日期选择
    indateChange(date, dateString, targetId) {
      if (dateString[0]&&dateString[1]) {
        this.filForm.tradetimeS = dateString[0]+' 00:00:00';
        this.filForm.tradetimeE = dateString[1]+' 23:59:59';
      } else {
        this.filForm.tradetimeS = "";
        this.filForm.tradetimeE = "";
      }
    },
    //样式
    setStyle(idx) {
      let style = {};
      //设置两条表单之间边距
      if ((idx + 1) % 3) {
        style.marginRight = this.itemDistance + "px";
      }

      return style;
    }
  }
};
</script>

<style lang="less" scoped>
.formFiltrate-box {
  width: 1188px;
  margin: 0 auto;
  height: 178px;
  overflow: hidden;
  background: #fff;
  position: relative;
  transition: all 0.2s;
  &.more {
    height: auto;
  }
  .tool-box {
    position: absolute;
    width: 180px;
    height: 32px;
    top: 16px;
    right: 30px;
    button {
      float: left;
      letter-spacing: 1px;
      padding: 0;
      + button {
        margin-left: 16px;
      }
    }
    .zk {
      margin-top: 3px;
      margin-right: 18px;
      float: right;
    }
  }
  .form {
    // max-width: 1040px;
    width: 100%;
    height: auto;
    padding: 66px 40px 0;
    float: left;
    // .ant-form-item {
    //   width: 33.33%;
    //   float: left;
    // }
    li {
      float: left;
      margin-bottom: 24px;
      > span {
        float: left;
        font-size: 14px;
        color: #262626;
        text-align: right;
        line-height: 32px;
      }
      .lab{
        width: 76px;
      }
      .form-item-box {
        width: 230px;
        height: 32px;
        // border: 1px solid;
        float: left;
        margin-left: 10px;
      }
    }
  }

  // 表单样式
  //输入框
  /deep/.ant-input-affix-wrapper .ant-input {
    padding: 0 16px;
    line-height: 32px;
    color: #262626;
    font-size: 14px;
    background-color: #fff;
    // border: 1px solid #eee;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  //下拉选择框
  /deep/.ant-select {
    width: 100%;
    .ant-select-selection__rendered {
      margin-right: 24px;
      overflow: hidden;
      ul {
        width: 999px;
      }
    }
    .ant-select-selection {
      border: 1px solid #ddd;
    }
    .ant-select-selection__rendered {
      margin-left: 16px;
    }
  }
  //日期选择框
  /deep/.ant-calendar-picker {
    width: 100%;
    .ant-calendar-picker-input {
      border: 1px solid #ddd;
    }
    .ant-calendar-picker-input.ant-input {
      text-align: left;
    }
    .ant-calendar-range-picker-input {
      width: 39%;
    }
  }
  /deep/.ant-cascader-picker {
    width: 100%;
  }
  //数字选择框
  .number-range-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-input-number {
      width: 44%;
    }
  }
}
</style>
