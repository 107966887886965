export default {
  nav1: [
    {
      title: "我的余额",
      path: "/",
      active: false
    },
    {
      title: "资金认领",
      path: "/finaceClaim",
      active: false
    }
  ]
};
